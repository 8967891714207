import { RepositoryFactory } from "../repositories/RepositoryFactory";
const QueueDeviceRepository = RepositoryFactory.get("queueDevice");
const QueueUnitRepository = RepositoryFactory.get('queueUnit');
const UserNotificationRepository = RepositoryFactory.get("userNotification");
const ServerTimestampRepository = RepositoryFactory.get('serverTimestamp')
const QueueResourceRepository = RepositoryFactory.get("queueUser")


import qz from 'qz-tray'

import CallerType from "./../constants/caller-type-enum";

export default {
    namespaced: true,
    state: {
        userNotification: null,
        viewCaller: null,
        sessionId: null,
        historyUnitCaller: [],
        historyDokterCaller: [],
        queueDevice: null,
        unitQueue: null,
        showModalPrinter: false,
        listPrinter: [],
        defaultPrinter: null,
        isLoading: false,
        tenantPrinter: '',
        options: {
            host: 'localhost',
            port: {
                insecure: [8182]
            },
            usingSecure: false
        },
        settingPrinter: false,
        printerOptions: [
            "SERVER", //ganti jadi print via server
            "DIALOG", //ganti jadi print with dialog
        ],
        printingOption: null,
        printServers: []
    },
    mutations: {
        SET_VIEW_CALLER(state, res) {
            state.viewCaller = res;
            sessionStorage.setItem('viewCaller', res);
        },
        SET_SESSION_ID(state, res) {
            state.sessionId = res;
            sessionStorage.setItem('sessionId', res);
        },
        SET_RECENT_CALLER(state, res) {
            state.callerToggle = res;
        },
        SET_QUEUE_DEVICE(state, res) {
            state.queueDevice = res;
            sessionStorage.setItem('queueDevice', JSON.stringify(res));
        },
        SET_USER_NOTIFICATION(state, res) {
            state.userNotification = res;
        },
        SET_HISTORY_UNIT_CALLER(state, res) {
            let id = sessionStorage.getItem('sessionId')
            res.sessionId = id
            var index = state.historyUnitCaller.findIndex(el => el.sessionId == id);
            if (index > -1) {
                state.historyUnitCaller.splice(index, 1);
                state.historyUnitCaller.unshift(res);
            } else {
                if (state.historyUnitCaller.length >= 5) {
                    state.historyUnitCaller.pop();
                }
                state.historyUnitCaller.unshift(res);
            }
        },
        SET_HISTORY_DOKTOR_CALLER(state, res) {
            let id = sessionStorage.getItem('sessionId')
            res.sessionId = id

            var index = state.historyDokterCaller.findIndex(el => el.sessionId == id);
            if (index > -1) {
                state.historyDokterCaller.splice(index, 1);
                state.historyDokterCaller.unshift(res);
            } else {
                if (state.historyDokterCaller.length >= 5) {
                    state.historyDokterCaller.pop();
                }
                state.historyDokterCaller.unshift(res);
            }
        },
        SET_UNIT_QUEUE(state, res) {
            state.unitQueue = res
        },
        SET_SHOW_MODAL_PRINTER(state, res) {
            state.showModalPrinter = res
        },
        SET_LIST_PRINTER(state, res) {
            state.listPrinter = res
            localStorage.setItem('printerList', JSON.stringify(res))
        },
        SET_DEFAULT_PRINTER(state, res) {
            state.defaultPrinter = res
            localStorage.setItem('defaultPrinter', res)
        },
        SET_LOADING(state, res) {
            state.isLoading = res
        },
        SET_SETTING_PRINTER(state, res) {
            state.settingPrinter = res
        },
        SET_PRINTING_OPTION(state, res) {
            state.printingOption = res
            localStorage.printingOption = res
        },
        SET_PRINT_SERVERS(state, res) {
            state.printServers = res
        },
        SET_TENANT_PRINTER(state, res) {
            state.tenantPrinter = res
            localStorage.setItem('tenantPrinter', res)
        }
    },
    actions: {
        SAVE_QUEUE_DEVICE({ dispatch }, res) {
            if (res.id == null) {
                dispatch('POST_QUEUE_DEVICE', res);
            } else {
                dispatch('PUT_QUEUE_DEVICE', res);
            }
        },
        async POST_QUEUE_DEVICE({ commit, dispatch }, res) {
            try {
                const response = await QueueDeviceRepository.createQueueDevice(res);
                commit('SET_QUEUE_DEVICE', response.data);
                dispatch('NOTES_HISTORY', response.data);
            } catch (err) {
                toastr.error(err);
            }
        },
        async PUT_QUEUE_DEVICE({ commit, dispatch }, res) {
            try {
                const response = await QueueDeviceRepository.updateQueueDevice(res);
                commit('SET_QUEUE_DEVICE', response.data);
                dispatch('NOTES_HISTORY', response.data);
            } catch (err) {
                toastr.error(err);
            }
        },
        async NOTES_HISTORY({ commit, dispatch, rootState }, res) {
            if (res.queueUnits.length > 0) {
                const objecHistory = {
                    type: CallerType.UNIT_CALLER,
                    lastUpdate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                    selectedUnit: res.queueUnits[0],
                    callerName: rootState.UnitCallerStore.unitCallerName
                }
                if (objecHistory.selectedUnit.displayName1 == null) {
                    dispatch('PUT_QUEUE_DEVICE', res)
                } else {
                    dispatch('GET_UNIT_QUEUE', objecHistory.selectedUnit.id)
                    commit('SET_HISTORY_UNIT_CALLER', objecHistory);
                }
            };
            if (res.dokters.length > 0) {
                const objectHistory = {
                    type: CallerType.DOCTOR_CALLER,
                    lastUpdate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                    selectedDokter: res.dokters[0],
                    bookmarkDokter: rootState.DoctorCallerStore.listBookmarkDokter
                };
                commit('SET_HISTORY_DOKTOR_CALLER', objectHistory);
            };
        },
        async POST_USER_NOTIFICATION({ commit }, res) {
            try {
                const response = await UserNotificationRepository.create(res);
                commit('SET_USER_NOTIFICATION', response.data);
            } catch (err) {
                toastr.error(err);
            }
        },
        async DELETE_QUEUE_DEVICE({ state }) {
            try {
                await QueueDeviceRepository.deleteQueueDevice(state.queueDevice.id);
            } catch (err) {

            }
        },
        async GET_UNIT_QUEUE({ commit }, res) {
            try {
                const { data } = await QueueUnitRepository.getQueueUnit(res)
                const diplay2 =  data.displayName2 ? data.displayName2 : ''
                commit('SET_UNIT_QUEUE', `${data.displayName1} ${diplay2}`);
            } catch (error) {
                toastr.error('Failed fetch unit queue');
            }
        },
    }
}